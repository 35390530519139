.genericButtonStyle {
  border: none;
  background: none;
  position: relative;
  background-color: transparent;
  outline: none;
  font-family: Lato;
  height: inherit;
  width: fit-content;
  line-height: inherit;
  border-radius: 4px;
  padding: 0px;
  margin: 0px;
}
